<template>
  <div id="order-view-action-cancel-or-refuse">
    <b-button
      id="order-view-action-cancel-or-refuse-btn"
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      variant="danger"
      class="mb-75"
      block
      @click.prevent="refusalEdition = true"
    >
      <feather-icon :icon="btnIcon || 'XCircleIcon'" size="20" class="align-middle" />
      <span class="ml-25 align-middle"> {{ btnText || $t('action.cancel') }} </span>
    </b-button>

    <app-sidebar-form
      form-name="order-view-action-cancel-or-refuse"
      :edition="refusalEdition"
      edition-mode-title="edit"
      :title="refuseTitle"
      :entity="refusal"
      :submit-btn-text="btnText || $t('action.cancel')"
      :submit-btn-icon="btnIcon || 'XCircleIcon'"
      submit-btn-variant="danger"
      @update:edition="refusalEdition = $event"
      @update:entity="cancelOrRefuse($event)"
    >
      <template #fields="data">
        <b-row>
          <b-col cols="12">
            <!-- reason-->
            <validation-provider
              #default="{ errors }"
              :name="$t('offer.max_subscription')"
              rules="required"
              vid="order-view-action-cancel-or-refuse-reason-provider"
            >
              <b-form-group :label="$t('common.reason')" label-for="order-view-action-cancel-or-refuse-reason" class="validation-required">
                <b-form-input
                  id="order-view-action-cancel-or-refuse-reason"
                  v-model="data.item.reason"
                  type="text"
                  :placeholder="$t('common.reason')"
                  :state="errors[0] ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <!-- comment -->
            <validation-provider #default="{ errors }" :name="$t('common.comment')" rules="" vid="order-view-action-cancel-or-refuse-comment-provider">
              <b-form-group :label="$t('common.comment')" label-for="order-view-action-cancel-or-refuse-comment">
                <b-form-textarea
                  id="order-view-action-cancel-or-refuse-comment"
                  v-model="data.item.comment"
                  :placeholder="$t('common.comment')"
                  :state="errors[0] ? false : null"
                  no-resize
                  rows="4"
                  class="hide-scroll"
                  max-rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </template>
    </app-sidebar-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import { patchQuoteRefuseRequest, patchQuoteCancelRequest, patchQuoteCancelOrderRequest } from '@/request/globalApi/requests/quoteRequests'

import AppSidebarForm from '@/components/AppSidebarForm.vue'

export default {
  name: 'OrderViewActionCancelOrRefuse',

  components: { AppSidebarForm },

  props: {
    btnText: {
      type: String,
      default: '',
    },

    btnIcon: {
      type: String,
      default: '',
    },

    refuseTitle: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      refusalEdition: false,
      refusal: { reason: '', comment: '' },
    }
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'isQuoteOwner']),
    ...mapFields('quote', ['quoteLoading']),
  },

  methods: {
    ...mapActions('quote', ['fetchQuote']),

    cancelOrRefuse(refusal) {
      this.quoteLoading = true
      if (this.quoteSelected.quote.state === 'awaiting_for_services_completed' || this.quoteSelected.quote.state === 'delivered') {
        return patchQuoteCancelOrderRequest(this.quoteSelected.quote.id, refusal, this.$store.getters['quote/quoteTitleAndId'])
          .then(() => {
            this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
              this.quoteLoading = false
            })
          })
          .catch(err => {
            err && (this.quoteLoading = false)
          })
      }
      if (this.isQuoteOwner) {
        return patchQuoteRefuseRequest(this.quoteSelected.quote.id, refusal, this.$store.getters['quote/quoteTitleAndId'])
          .then(() => {
            this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
              this.quoteLoading = false
            })
          })
          .catch(err => {
            err && (this.quoteLoading = false)
          })
      }

      return patchQuoteCancelRequest(this.quoteSelected.quote.id, refusal, this.$store.getters['quote/quoteTitleAndId'])
        .then(() => {
          this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
            this.quoteLoading = false
          })
        })
        .catch(err => {
          err && (this.quoteLoading = false)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.hide-scroll::-webkit-scrollbar {
  display: none;
}
</style>
